import { createContext } from "react";

type ContextProps = {
  snackbarMessage: string;
  setSnackbarMessage: any;
};

const SnackbarContext = createContext<ContextProps>({
  snackbarMessage: "",
  setSnackbarMessage: () => {},
});

export default SnackbarContext;
