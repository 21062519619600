import React, { useCallback, useEffect, useState } from "react";

import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

import { createStyles, makeStyles } from "@material-ui/core";

import SnackbarContext from "./snackbarContext";

import { Gyanberry_blue, Gyanberry_white_full, Gyanberry_wrong } from "../../utils/themeProvider";

const useStyles = makeStyles(() =>
  createStyles({
    closeSnackbarIcon: {
      color: Gyanberry_white_full,
    },
    snackbarContent: {
      borderRadius: "8px",
      fontWeight: "bold",
      fontFamily: "Montserrat",
    },
    snackbarNormal: {
      backgroundColor: Gyanberry_blue,
    },
    snackbarError: {
      backgroundColor: Gyanberry_wrong,
    },
  }),
);

const SnackbarState: React.FC = ({ children }) => {
  const classes = useStyles();
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarType, setSnackbarType] = useState<"snackbarNormal" | "snackbarError">("snackbarNormal");

  useEffect(() => {
    !!snackbarMessage && setShowSnackbar(true);
  }, [snackbarMessage]);

  const onClose = useCallback(() => {
    setShowSnackbar(false);
  }, []);

  const onExited = useCallback(() => {
    setSnackbarMessage("");
  }, []);

  const setSnackbar = (message: string, type: "snackbarNormal" | "snackbarError" = "snackbarNormal") => {
    setSnackbarMessage(message);
    setSnackbarType(type);
  };

  return (
    <>
      <SnackbarContext.Provider
        value={{
          snackbarMessage: snackbarMessage,
          setSnackbarMessage: setSnackbar,
        }}
      >
        {children}
      </SnackbarContext.Provider>

      <Snackbar
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={showSnackbar}
        onClose={onClose}
        onExited={onExited}
      >
        <SnackbarContent
          action={
            <IconButton size="small" onClick={onClose}>
              <CloseIcon fontSize="small" className={classes.closeSnackbarIcon} />
            </IconButton>
          }
          className={`${classes.snackbarContent} ${classes[snackbarType]}`}
          message={snackbarMessage}
        />
      </Snackbar>
    </>
  );
};

export default SnackbarState;
