import axiosWrapper from "./axiosWrapper";
import endpoints from "./endpoints";

import { AdditionalUserData } from "../interfaces/AdditionalUserData";
import { Conversation } from "../interfaces/Conversation";
import { RefreshedMessages } from "../interfaces/RefreshedMessages";

/**
 * Makes GET request to the API to get dashboard summary
 */
export function getDashboard() {
  return axiosWrapper.get(endpoints.get.dashboard());
}

/**
 * Makes GET request to the API in terms to obtain user data
 */
export function getMe() {
  return axiosWrapper.get<AdditionalUserData>(endpoints.get.me());
}

/**
 * Makes GET request to the API in terms to obtain user data
 */
export function getConversations(params: any) {
  return axiosWrapper.get<Conversation[]>(`${endpoints.get.conversations()}?${new URLSearchParams(params)}`);
}

/**
 * Makes GET request to the API in terms to obtain messages
 */
export function getMessages(uuid: string, params: any) {
  return axiosWrapper.get(`${endpoints.get.messages(uuid)}?${new URLSearchParams(params)}`);
}

/**
 * Makes GET request to the API in terms to obtain fresh messages
 */
export function getFreshMessages(uuid: string, params?: any) {
  return axiosWrapper.get<RefreshedMessages>(`${endpoints.get.messagesRefresh(uuid)}?${new URLSearchParams(params)}`);
}

/**
 * Makes POST request to the API to send message
 */
export function postMessage(uuid: string, message: string) {
  return axiosWrapper.post(endpoints.post.messages(uuid), { content: message });
}

/**
 * Makes POST request to the API to change conversation status
 */
export function postNewConversationStatus(uuid: string, status: number) {
  return axiosWrapper.post(endpoints.post.changeConversationStatus(uuid, status));
}

/**
 * Makes POST request to the API to register user
 * @param data
 */
export function postRegistration(data: any) {
  return axiosWrapper.post(endpoints.post.register(), data);
}

/**
 * Makes POST request to the API to resend confirmation email
 * @param email
 */
export function postSendConfirmationEmail(email: string) {
  return axiosWrapper.post(endpoints.post.resendConfirmation(), {
    email,
  });
}

/**
 * Makes POST request to the API to send reset password email
 * @param email
 */
export function postSendResetPasswordEmail(email: string) {
  return axiosWrapper.post(endpoints.post.resetPassword(), {
    email,
  });
}

/**
 * Makes POST request to the API to verify recaptcha token
 * @param token
 */
export function postVerifyRecaptcha(token: string) {
  return axiosWrapper.post(endpoints.post.verifyRecaptcha(), {
    token,
  });
}

/**
 * Makes PUT request to the API to update user data
 * @param data
 */
export function putUpdateUser(data: any) {
  return axiosWrapper.put(endpoints.put.updateUser(), data);
}

/**
 * Makes PATCH request to the API to update user data
 * @param data
 */
export function patchUpdateUser(data: any) {
  return axiosWrapper.patch(endpoints.patch.updateUser(), data);
}

/**
 * Makes GET request to the API to get a list of users
 */
export function getUsers(params: any) {
  return axiosWrapper.get(`${endpoints.get.users()}?${new URLSearchParams(params)}`);
}

/**
 * Makes GET request to the API to get a user details
 */
export function getUser(userId: string) {
  return axiosWrapper.get(endpoints.get.user(userId));
}

/**
 * Makes PUT request to the API to update user's status
 */
export function putUpdateStatus(userId: string, data: any) {
  return axiosWrapper.put(endpoints.put.changeStatus(userId), data);
}

/**
 * Makes PUT request to the API to update user's data in admin panel
 */
export function putEditUser(userId: string, data: any) {
  return axiosWrapper.put(endpoints.put.editUser(userId), data);
}

/**
 * Makes GET request to the API to get users in .xlsx format
 */
export function getUsersXLSX() {
  return axiosWrapper.get(endpoints.get.exportUsers(), { responseType: "blob" });
}

/**
 * Makes GET request to the API to get schools
 */
export function getSchools(params: any) {
  return axiosWrapper.get(`${endpoints.get.schools()}?${new URLSearchParams(params)}`);
}

/**
 * Makes GET request to the API to get institutions
 */
export function getInstitutions(params: any) {
  return axiosWrapper.get(`${endpoints.get.institutions()}?${new URLSearchParams(params)}`);
}

/**
 * Makes GET request to the API to get school details
 */
export function getSchool(schoolId: string) {
  return axiosWrapper.get(`${endpoints.get.school(schoolId)}`);
}

/**
 * Makes GET request to the API to get institute details
 */
export function getInstitution(instituteId: string) {
  return axiosWrapper.get(`${endpoints.get.institute(instituteId)}`);
}

/**
 * Makes DELETE request to the API to delete account
 */
export function deleteAccount() {
  return axiosWrapper.delete(endpoints.delete.deleteAccount());
}

/**
 * Makes POST request to the API to request a connection with school
 */
export function postRequestConnection(schoolUUID: string) {
  return axiosWrapper.post(endpoints.post.requestConnection(schoolUUID));
}

/**
 * Makes DELETE request to the API to remove a connection with school
 */
export function deleteRequestConnection(schoolUUID: string) {
  return axiosWrapper.delete(endpoints.delete.removeConnection(schoolUUID));
}

/**
 * Makes GET request to the API to get visit details
 */
export function getVisit(visitId: string) {
  return axiosWrapper.get(`${endpoints.get.visit(visitId)}`);
}

/**
 * Makes GET request to the API to get visits
 */
export function getVisits(params: any) {
  return axiosWrapper.get(`${endpoints.get.visits()}?${new URLSearchParams(params)}`);
}

/**
 * Makes POST request to the API to schedule a visit
 */
export function postVisit(uuid: string, data: any) {
  return axiosWrapper.post(endpoints.post.visit(uuid), data);
}

/**
 * Makes PATCH request to the API to accept / reject / edit a visit
 */
export function patchVisit(visitUUID: string, data: any) {
  return axiosWrapper.patch(endpoints.patch.visit(visitUUID), data);
}
