import "firebase/auth";

import React, { useEffect, useRef, useState } from "react";

import firebase from "../../firebase";
import pick from "lodash/pick";
import throttle from "lodash/throttle";

import UserContext from "./userContext";

import { getMe } from "../../api";
import { PropsChild } from "../../interfaces/PropsChild";
import { UserData } from "../../interfaces/UserData";

const getUserDataFromLocalStorage = (): UserData => {
  return JSON.parse(localStorage.getItem("userData") || "null");
};

const UserState = (props: PropsChild) => {
  const [authenticated, setAuthenticated] = useState<boolean>(localStorage.getItem("userData") !== null);
  const [userData, setUserData] = useState<UserData | null>(getUserDataFromLocalStorage());

  const throttledUserData = useRef(
    //@ts-ignore
    throttle(userData => saveUserDataInLocalStorage(userData), 1000),
  );

  useEffect(() => {
    firebase.auth().onIdTokenChanged(async userDataChanges => {
      if (userData && userDataChanges) {
        try {
          const token = await firebase.auth().currentUser!.getIdToken();
          saveUserDataInLocalStorage({
            token,
          });
        } catch (err) {
          logoutUser();
        }
      } else {
        logoutUser();
      }
    });
  }, [userData]);

  useEffect(() => throttledUserData.current(userData), [userData]);

  const saveUserDataInLocalStorage = (userData: any) => {
    try {
      if (!userData) {
        return;
      }
      const serializedUser = getUserDataFromLocalStorage();
      const updatedUser = {
        ...serializedUser,
        ...userData,
        /* additionalUserData: {
          ...serializedUser.additionalUserData,
          ...additionalUserData,
        }, */
      };
      localStorage.setItem("userData", JSON.stringify(updatedUser));
    } catch (err) {
      console.log("Error while saving user data", err);
    }
  };

  const saveAdditionalUserData = (additionalUserData: any) => {
    if (!additionalUserData) {
      return {
        success: false,
        message: "No additional user data",
      };
    }

    // @ts-ignore
    setUserData(prev => ({
      ...prev,
      additionalUserData: {
        ...(prev && prev.additionalUserData),
        ...additionalUserData,
      },
    }));

    return {
      success: true,
      message: null,
    };
  };

  const saveQuestionnaire = (questionnaireData: any) => {
    try {
      if (!questionnaireData) {
        return {
          success: false,
          message: "No questionnaire data",
        };
      }

      // @ts-ignore
      setUserData(prev => ({
        ...prev,
        additionalUserData: {
          ...(prev && prev.additionalUserData),
          questionnaire: {
            ...(prev && prev.additionalUserData && prev.additionalUserData.questionnaire),
            ...questionnaireData,
          },
        },
      }));

      return {
        success: true,
        message: null,
      };
    } catch (err) {
      console.error(err);

      return {
        success: false,
        message: err.message,
      };
    }
  };

  const loginUser = async (email: string, password: string) => {
    try {
      const result = await firebase.auth().signInWithEmailAndPassword(email, password);

      const token = await firebase.auth().currentUser!.getIdToken();
      const data = {
        ...pick(result.user, ["displayName", "email", "emailVerified", "phoneNumber", "photoURL", "uid"]),
        token,
      };

      // @ts-ignore
      setUserData(data);
      saveUserDataInLocalStorage(data);

      const response = await getMe();
      const haveMessages =
        response.data.position === "admin" || !response.data.questionnaire.messagesCount
          ? false
          : response.data.questionnaire.messagesCount > 0;

      saveAdditionalUserData({
        ...response.data,
        haveMessages,
      });
      setAuthenticated(true);

      return {
        success: true,
        message: null,
      };
    } catch (error) {
      if (error) {
        if (
          ["auth/wrong-password", "auth/user-not-found", "auth/too-many-requests"].includes(error.code) ||
          error.response.status === 400
        ) {
          error.message = "Invalid email or password.";
          delete error.response;
        } else if (error.response.status === 403) {
          error.message = "Account is still in the verification process.";
          delete error.response;
        } else if (error.response.status === 401) {
          error.message = "Your account is temporarily unavailable, please contact SchoolDoor support team.";
          delete error.response;
        }
      }

      setAuthenticated(false);
      await firebase.auth().signOut();

      return {
        success: false,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message,
      };
    }
  };

  const logoutUser = () => {
    setAuthenticated(false);
    localStorage.removeItem("userData");
    setUserData(null);
  };

  return (
    <UserContext.Provider
      value={{
        authenticated,
        loginUser,
        setAdditionalUserData: saveAdditionalUserData,
        setQuestionnaire: saveQuestionnaire,
        user: userData,
        logoutUser,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
