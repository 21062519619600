export default {
  get: {
    conversations: () => "/messages",
    dashboard: () => "/dashboard",
    exportUsers: () => "/admin/export",
    me: () => "/me",
    messages: (entityUUID: string) => `/messages/${entityUUID}`,
    messagesRefresh: (entityUUID: string) => `/messages/refresh/${entityUUID}`,
    institute: (instituteId: string) => `/university/${instituteId}`,
    institutions: () => "/university",
    school: (schoolId: string) => `/school/${schoolId}`,
    schools: () => "/school",
    user: (userId: string) => `/admin/user/${userId}`,
    users: () => "/admin/user",
    visit: (visitId: string) => `/visit/${visitId}`,
    visits: () => "/visit",
  },
  post: {
    changeConversationStatus: (UUID: string, status: number) => `/messages/status/${UUID}/${status}`,
    messages: (UUID: string) => `/messages/${UUID}`,
    register: () => "/register",
    resendConfirmation: () => "/resend-email-confirmation",
    resetPassword: () => "/reset-password",
    requestConnection: (schoolUUID: string) => `/connections/${schoolUUID}`,
    verifyRecaptcha: () => "/verify-recaptcha",
    visit: (secondPartyUUID: string) => `/visit/${secondPartyUUID}`,
  },
  put: {
    changeStatus: (userId: string) => `/admin/user/change-status/${userId}`,
    editUser: (userId: string) => `/admin/user/${userId}`,
    updateUser: () => "/update-user",
  },
  patch: {
    updateUser: () => "/update-user",
    visit: (visitUUID: string) => `/visit/${visitUUID}`,
  },
  delete: {
    deleteAccount: () => "/delete-account",
    removeConnection: (schoolUUID: string) => `/connections/${schoolUUID}`,
  },
};
