import React from "react";

import { useHistory } from "react-router";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import LinkComponent from "../components/LinkComponent";

import { UnprotectedLayoutProps } from "../interfaces/UnprotectedLayoutProps";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    mainContainer: {
      flexWrap: "unset",
      flexDirection: "column",
    },
    contentBlock: {
      display: "inline-flex",
      flexGrow: 100,
      verticalAlign: "top",
      overflowY: "scroll",
      maxHeight: "100vh",
    },
    arrowPadding: {
      paddingTop: theme.spacing(4.5),
    },
    logoBox: {
      paddingTop: theme.spacing(6),
      paddingRight: theme.spacing(8),
    },
    unsetFlexBasis: {
      flexBasis: "unset",
    },
  }),
);

const UnprotectedPageLayout: React.FC<UnprotectedLayoutProps> = props => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Box className={classes.contentBlock}>
        <Grid container alignContent="flex-start" spacing={0} className={classes.mainContainer}>
          <Grid className={classes.unsetFlexBasis} item xs={12}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" flexWrap="wrap">
              <Box>
                {props.backButtonComponent && (
                  <Box data-testid="backButton" className={classes.arrowPadding}>
                    {props.backButtonComponent}
                  </Box>
                )}
              </Box>
              <Box className={classes.logoBox}>
                <LinkComponent
                  action={() => {
                    props.setBackButtonComponent(null);
                    history.push("/");
                  }}
                >
                  <img src="/images/school-doors-logo.svg" alt="logo" />
                </LinkComponent>
              </Box>
            </Box>
          </Grid>
          <Grid className={classes.unsetFlexBasis} item xs={12}>
            <Box display="flex" flexDirection="row" justifyContent="center" alignContent="center">
              {props.children}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default UnprotectedPageLayout;
