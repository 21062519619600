import React from "react";

import Link from "@material-ui/core/Link";

import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      cursor: "pointer",
    },
  }),
);

const LinkComponent: React.FC<{ action: any; className?: string; tabIndex?: number }> = ({
  action,
  className,
  children,
  tabIndex,
}) => {
  const classes = useStyles();
  return (
    <Link className={`${classes.link} ${!!className && className}`} onClick={action} tabIndex={tabIndex}>
      {children}
    </Link>
  );
};

export default LinkComponent;
