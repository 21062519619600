export const DASHBOARD_ADMIN_PATH = "/admin";
export const DASHBOARD_ADMIN_SUBSCRIPTIONS_PATH = "/admin/subscriptions";
export const DASHBOARD_ADMIN_NOTIFICATIONS_PATH = "/admin/notifications";
export const DASHBOARD_ADMIN_USERS_PATH = "/admin/user";
export const DASHBOARD_ADMIN_USER_PATH = "/admin/user/:userId";
export const DASHBOARD_PATH = "/dashboard";
export const DASHBOARD_ACCOUNT_PATH = "/dashboard/account";
export const DASHBOARD_OVERVIEW_PATH = "/dashboard/overview";
export const DASHBOARD_MESSAGES_PATH = "/dashboard/messages";
export const DASHBOARD_MESSAGES_UUID_PATH = "/dashboard/messages/:uuid";
export const DASHBOARD_NOTIFICATIONS_PATH = "/dashboard/notifications";
export const DASHBOARD_SCHOOLS_PATH = "/dashboard/schools";
export const DASHBOARD_SCHOOL_PATH = "/dashboard/schools/:schoolId";
export const DASHBOARD_INSTITUTIONS_PATH = "/dashboard/institutions";
export const DASHBOARD_INSTITUTION_PATH = "/dashboard/institutions/:schoolId";
export const DASHBOARD_VISITS_PATH = "/dashboard/visits";
export const DASHBOARD_VISIT_PATH = "/dashboard/visits/:visitId";
export const FIREBASE_AUTH_ACTION_PATH = "/auth";
export const RESET_NEW_PASSWORD_PATH = "/reset-password-form";
export const RESET_NEW_PASSWORD_SUCCESS_PATH = "/reset-password-success";
export const RESET_PASSWORD_CONFIRMATION_PATH = "/reset-password-confirmation";
export const RESET_PASSWORD_PATH = "/reset-password";
export const SCHOOL_QUESTIONNAIRE_PATH = "/sc-questionnaire";
export const SCHOOLS_PATH = "/schools";
export const SIGN_IN_PATH = "/signin";
export const SIGN_UP_PATH = "/signup";
export const SIGN_UP_CONFIRMATION_PATH = "/signup-confirmation";
export const UNIVERSITY_QUESTIONNAIRE_PATH = "/uo-questionnaire";
export const VERIFY_EMAIL_PATH = "/verify-email";
export const VERIFY_EMAIL_RESEND_PATH = "/verify-email-resend";
