import React from "react";

import { ThemeProvider } from "@material-ui/core/styles";
import { createBrowserHistory } from "history";
import { Router } from "react-router";

import "react-circular-progressbar/dist/styles.css";

import Routes from "./routes";
import SnackbarState from "./context/snackbarContext/snackbarState";
import theme from "./utils/themeProvider";
import UserState from "./context/userContext/userState";

const history = createBrowserHistory();

const App: React.FC = () => {
  return (
    <UserState>
      <SnackbarState>
        <Router history={history}>
          <ThemeProvider theme={theme}>
            <Routes />
          </ThemeProvider>
        </Router>
      </SnackbarState>
    </UserState>
  );
};

export default App;
