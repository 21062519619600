import React, { lazy, useContext, Suspense } from "react";

import { Switch, Route, Redirect } from "react-router-dom";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import UnprotectedPageLayout from "./layouts/UnprotectedPageLayout";
import UserContext from "./context/userContext/userContext";

import {
  DASHBOARD_ADMIN_PATH,
  DASHBOARD_PATH,
  FIREBASE_AUTH_ACTION_PATH,
  RESET_NEW_PASSWORD_PATH,
  RESET_NEW_PASSWORD_SUCCESS_PATH,
  RESET_PASSWORD_CONFIRMATION_PATH,
  SCHOOL_QUESTIONNAIRE_PATH,
  UNIVERSITY_QUESTIONNAIRE_PATH,
} from "./paths";

const AdminDashboardPage = lazy(() => import("./views/AdminDashboardPage/AdminDashboardPage"));
const DashboardPage = lazy(() => import("./views/DashboardPage/DashboardPage"));
const FirebaseAuthRedirector = lazy(() => import("./components/FirebaseAuthRedirector"));
const ResetNewPasswordPage = lazy(() => import("./views/UnprotectedPage/ResetNewPasswordPage/ResetNewPasswordPage"));
const ResetNewPasswordSuccessPage = lazy(() =>
  import("./views/UnprotectedPage/ResetNewPasswordSuccessPage/ResetNewPasswordSuccessPage"),
);
const ResetPasswordConfirmationPage = lazy(() =>
  import("./views/UnprotectedPage/ResetPasswordConfirmationPage/ResetPasswordConfirmationPage"),
);
const SchoolQuestionnairePage = lazy(() => import("./views/SchoolQuestionnairePage/SchoolQuestionnairePage"));

const UniveristyQuestionnairePage = lazy(() =>
  import("./views/UniversityQuestionnairePage/UniversityQuestionnairePage"),
);
const UnprotectedPage = lazy(() => import("./views/UnprotectedPage/UnprotectedPage"));

const Routes = () => {
  const context = useContext(UserContext);

  return (
    <Suspense
      fallback={
        <Backdrop open={true} style={{ backgroundColor: "#FFFFFF" }}>
          <CircularProgress color="primary" />
        </Backdrop>
      }
    >
      {(!context.authenticated || (context.authenticated && !context.user?.additionalUserData)) && (
        <Switch>
          <Route path="/" component={UnprotectedPage} />
        </Switch>
      )}
      {context.authenticated && context.user?.additionalUserData && (
        <Switch>
          <Route path={DASHBOARD_PATH} component={DashboardPage} />
          {context.user!.additionalUserData.position === "admin" && (
            <Switch>
              <Route path={DASHBOARD_ADMIN_PATH} component={AdminDashboardPage} />
              <Route render={() => <Redirect to={{ pathname: DASHBOARD_ADMIN_PATH }} />} />
            </Switch>
          )}
          {context.user!.additionalUserData.position === "sc" &&
            !context.user!.additionalUserData.questionnaire.filled && (
              <Route exact path={SCHOOL_QUESTIONNAIRE_PATH} component={SchoolQuestionnairePage} />
            )}
          )}
          {context.user!.additionalUserData.position === "uo" &&
            !context.user!.additionalUserData.questionnaire.filled && (
              <Route exact path={UNIVERSITY_QUESTIONNAIRE_PATH} component={UniveristyQuestionnairePage} />
            )}
          )}
          <Route
            exact
            path={FIREBASE_AUTH_ACTION_PATH}
            component={() => (
              <UnprotectedPageLayout>
                <FirebaseAuthRedirector />
              </UnprotectedPageLayout>
            )}
          />
          <Route
            exact
            path={RESET_NEW_PASSWORD_PATH}
            component={() => (
              <UnprotectedPageLayout>
                <ResetNewPasswordPage />
              </UnprotectedPageLayout>
            )}
          />
          <Route
            exact
            path={RESET_NEW_PASSWORD_SUCCESS_PATH}
            component={() => (
              <UnprotectedPageLayout>
                <ResetNewPasswordSuccessPage />
              </UnprotectedPageLayout>
            )}
          />
          <Route
            exact
            path={RESET_PASSWORD_CONFIRMATION_PATH}
            component={() => (
              <UnprotectedPageLayout>
                <ResetPasswordConfirmationPage />
              </UnprotectedPageLayout>
            )}
          />
          <Route render={() => <Redirect to={{ pathname: DASHBOARD_PATH }} />} />
        </Switch>
      )}
    </Suspense>
  );
};

export default Routes;
