import { createMuiTheme } from "@material-ui/core/styles";

export const Gyanberry_black = "#000100";
export const Gyanberry_black_light = "#1C1C1A";
export const Gyanberry_blue = "#2D4059";
export const Gyanberry_blue_link = "#2D4059";
export const Gyanberry_cloud_grey = "#E0E0E0";
export const Gyanberry_gray = "#555555";
export const Gyanberry_gray_medium = "#6B6B6B";
export const Gyanberry_gray_light = "#8C8C87";
export const Gyanberry_orange = "#EA5455";
export const Gyanberry_yellow = "#FFD460";
export const Gyanberry_yellow_light = "#FFEEBF";
export const Gyanberry_yellow_hover = "#FFBB00";
export const Gyanberry_white = "#F5F5F5";
export const Gyanberry_white_full = "#FFFFFF";
export const Gyanberry_green = "#00bf26";
export const Gyanberry_green_medium = "#6BC242";
export const Gyanberry_wrong = "#D90027";
export const Gyanberry_wrong_semi_light = "#FFD4D4";
export const Gyanberry_wrong_light = "#F9FBFF";

export const primary = Gyanberry_yellow;
export const errorLight = Gyanberry_wrong_light;
export const errorMain = Gyanberry_wrong;
export const textPrimary = Gyanberry_black;
export const textSecondary = Gyanberry_gray;
export const linkColor = Gyanberry_blue_link;
export const labelGrey = Gyanberry_gray_light;
export const messageGray = "#EAEBEE";
export const messageYellow = "rgba(255, 212, 96, 0.5)";
export const autoRefreshTrack = "rgba(107, 194, 66, 0.5)";
export const autoRefreshFocus = "rgba(107, 194, 66, 0.1)";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Montserrat",
    fontWeightRegular: 400,
    fontWeightBold: 600,
    htmlFontSize: 16,
  },
  palette: {
    common: {
      black: textPrimary,
    },
    primary: {
      main: primary,
      contrastText: "#FFF",
    },
    error: {
      light: errorLight,
      main: errorMain,
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
    },
    background: {
      default: "#FFF",
    },
    action: {
      disabledBackground: primary,
      disabled: Gyanberry_blue,
    },
  },
  shape: {
    borderRadius: 3,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiTableRow: {
      root: {
        "&:last-child td": {
          borderBottom: 0,
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: "16px 8px",
      },
    },
    MuiCssBaseline: {
      "@global": {
        "*": {
          "scrollbar-width": "thin",
        },
        "*::-webkit-scrollbar": {
          width: "4px",
          height: "4px",
          color: Gyanberry_cloud_grey,
        },
      },
    },
    MuiTypography: {
      h1: {
        fontSize: "28px",
        fontWeight: 600,
      },
      h2: {
        fontSize: "27px",
        fontWeight: 600,
      },
      h3: {
        fontSize: "22px",
        fontWeight: 600,
      },
      h4: {
        fontSize: "22px",
        fontWeight: 400,
      },
      h5: {
        fontSize: "16px",
        fontWeight: 400,
      },
      h6: {
        fontSize: "16px",
        fontWeight: 600,
      },
    },
    MuiLink: {
      root: {
        color: linkColor,
        fontSize: "15px",
        fontWeight: 600,
      },
      underlineHover: {
        "&:hover": {
          textDecoration: "none",
        },
      },
    },
    //@ts-ignore
    MuiToggleButton: {
      root: {
        background: Gyanberry_white,
        color: Gyanberry_black,
        borderRadius: "19.5px",
        fontSize: "14px",
        padding: "9px 16px",
        height: "auto",
        lineHeight: 1.25,
        textTransform: "none",
        border: "solid 2px transparent",

        "&$selected": {
          borderLeft: "2px solid #f00 !important",
          marginLeft: "8px !important",
          color: Gyanberry_black,
          border: `solid 2px ${Gyanberry_orange}`,
          background: "transparent",
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: Gyanberry_white,
          },
        },
      },
    },
    MuiButton: {
      root: {
        color: Gyanberry_yellow,
        fontWeight: 600,
        fontSize: "16px",
        textTransform: "uppercase",
      },
      contained: {
        color: Gyanberry_blue,
        maxHeight: "100%",
        boxShadow: "0px 1px 0px #161D251A",
        borderRadius: 40,
        padding: "0px 32px",
        height: 48,
      },
      containedPrimary: {
        color: Gyanberry_blue,
        "&:hover": {
          backgroundColor: Gyanberry_yellow_hover,
        },
        "&:disabled": {
          backgroundColor: Gyanberry_yellow,
          opacity: 0.5,
        },
      },
      containedSecondary: {
        backgroundColor: Gyanberry_wrong,
        "&:hover": {
          backgroundColor: Gyanberry_wrong,
        },
        "&:disabled": {
          color: Gyanberry_white,
          backgroundColor: Gyanberry_wrong,
        },
      },
      outlined: {
        borderRadius: 40,
        padding: "0px 32px",
        height: 48,
        border: `solid 1.5px ${Gyanberry_gray_light}`,
        maxHeight: "100%",
        color: Gyanberry_gray_light,
        "&:disabled": {
          border: `solid 1.5px ${Gyanberry_gray_light}`,
          color: Gyanberry_gray_light,
        },
      },
    },
    MuiInput: {
      root: {
        fontSize: "16px",
        fontWeight: 500,
      },
      underline: {
        "&:before": {
          borderBottom: `1px solid ${labelGrey}`,
        },
        "&:after": {
          borderBottom: `2px solid ${labelGrey}`,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          fontSize: "12px",
          color: labelGrey,
        },
        "&$error": {
          color: labelGrey,
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: "16px",
        marginBottom: "0.35rem",
        fontWeight: 400,
        color: labelGrey,
      },
      shrink: {
        fontSize: "12px",
        transform: "translate(0, -0.05rem) scale(1);",
      },
      asterisk: {
        color: errorMain,
      },
    },
  },
});

export default theme;
