import "firebase/auth";

import Axios from "axios";
import firebase from "../firebase";

import { UserData } from "../interfaces/UserData";

const axiosWrapper = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosWrapper.interceptors.response.use(
  response => response,
  error => {
    return new Promise(async (_, rej) => {
      if (!error.response || error.response.status === 401 || error.response.status === 403) {
        await firebase.auth().signOut();
      }
      return rej(error);
    });
  },
);

axiosWrapper.interceptors.request.use(config => {
  const userData: UserData | null = JSON.parse(localStorage.getItem("userData") || "null");

  if (userData && userData.token) {
    config.headers.Authorization = `Bearer ${userData.token}`;
  }

  return config;
});

export default axiosWrapper;
