import { createContext } from "react";

import { UserData } from "../../interfaces/UserData";

type ContextProps = {
  user: UserData | null;
  authenticated: boolean;
  loginUser: any;
  setAdditionalUserData: any;
  setQuestionnaire: any;
  logoutUser: any;
};

const UserContext = createContext<ContextProps>({
  user: null,
  authenticated: false,
  loginUser: () => {},
  setAdditionalUserData: () => {},
  setQuestionnaire: () => {},
  logoutUser: () => {},
});

export default UserContext;
